import React, { useMemo } from 'react';

import { ReferenceAutocompleteInput } from 'ra-friendsofbabba';
import {
	ArrayInput,
	BooleanInput,
	DateInput,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleFormIterator,
	TextInput,
	useGetList,
	usePermissions,
	useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import {
	Grid,
	makeStyles,
} from '@material-ui/core';

import { TextFormDivider } from '../form';
import { EmployeeLangsInput } from './';
import AwesomeReferenceArrayInput from './AwesomeReferenceArrayInput';
import EuroInput from './EuroInput';

const useStyles = makeStyles((theme) => ({
	langs: {
		margin: 0,
		"& li": {
			borderBottom: "none",
		},
	},
	langsInput: {
		margin: 0,
	},
}));

const UserBasicInfoInput = ({ className, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const form = useFormState()?.values;
	const { data: langs } = useGetList("langs");
	const maxLangs = Object.keys(langs || {}).length;
	const employeeLangs = (form?.employee_langs || []).length;
	const { permissions } = usePermissions();
	const isCv = useMemo(() => permissions && permissions("cv"), [permissions]);
	const isEmployee = useMemo(
		() => permissions && permissions("employee"),
		[permissions]
	);

	return (
		<Grid container spacing={0}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<ReferenceInput
						{...props}
						source="employee_type_id"
						reference="employee-types"
						isRequired={true}
						validate={required()}
						filter={{
							ids: isCv ? [2] : isEmployee ? [1] : [1, 2],
						}}
					>
						<SelectInput helperText="resources.employees.fields.employee_type_id.help" />
					</ReferenceInput>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextFormDivider label="resources.employees.fields.basic_info" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextInput
						{...props}
						source="firstname"
						isRequired={true}
						validate={required()}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						{...props}
						source="lastname"
						isRequired={true}
						validate={required()}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<NumberInput
						{...props}
						source="phone"
						isRequired={true}
						validate={required()}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4}>
					<TextInput {...props} type="email" source="email" />
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextInput {...props} type="email" source="email_secondary" />
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextInput {...props} type="email" source="pec" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextInput {...props} source="tax_id_code" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<ReferenceAutocompleteInput
						{...props}
						source="birth_city_id"
						reference="cities"
						optionText="fullname"
						sort={{
							field: "name",
							order: "ASC",
						}}
					>
						<SelectInput />
					</ReferenceAutocompleteInput>
				</Grid>
				<Grid item xs={12} sm={6}>
					<DateInput {...props} source="birthdate" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextInput {...props} source="notes" multiline={true} rows={7} />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextFormDivider label="resources.employees.fields.addresses" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<ReferenceAutocompleteInput
						{...props}
						source="city_id"
						reference="cities"
						isRequired={true}
						validate={required()}
						optionText="fullname"
						sort={{
							field: "name",
							order: "ASC",
						}}
					>
						<SelectInput />
					</ReferenceAutocompleteInput>
				</Grid>
				{form?.city_id && (
					<Grid item xs={12} sm={6}>
						<TextInput {...props} source="address" />
					</Grid>
				)}
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<ReferenceAutocompleteInput
						{...props}
						source="home_city_id"
						reference="cities"
						optionText="fullname"
						helperText="resources.employees.fields.home_city_id.help"
						sort={{
							field: "name",
							order: "ASC",
						}}
					>
						<SelectInput />
					</ReferenceAutocompleteInput>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextInput
						{...props}
						source="home_address"
						helperText="resources.employees.fields.home_address.help"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextFormDivider label="resources.employees.fields.skills" />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<AwesomeReferenceArrayInput
						{...props}
						source="qualifications"
						reference="qualifications"
						label="resources.employees.fields.qualifications"
						sort={{
							field: "name",
							order: "ASC",
						}}
						emptyText="-"
					>
						<TextInput source="name" validate={required()} fullWidth />
					</AwesomeReferenceArrayInput>
					<AwesomeReferenceArrayInput
						{...props}
						source="work_experiences"
						reference="jobs"
						label="resources.employees.fields.work_experiences"
						sort={{
							field: "name",
							order: "ASC",
						}}
						emptyText="-"
					>
						<TextInput source="name" validate={required()} fullWidth />
					</AwesomeReferenceArrayInput>
					<TextInput
						{...props}
						source="it_knowledge"
						multiline={true}
						rows={7}
						helperText="resources.employees.fields.it_knowledge.help"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ReferenceInput
						{...props}
						allowEmpty
						source="driving_license_id"
						reference="driving-licenses"
						emptyText="-"
						sort={{
							field: "name",
							order: "ASC",
						}}
					>
						<SelectInput helperText="resources.employees.fields.driving_license_id.help" />
					</ReferenceInput>
					<BooleanInput
						{...props}
						source="has_car"
						helperText="resources.employees.fields.has_car.help"
					/>
					<BooleanInput
						{...props}
						source="travel_availability"
						helperText="resources.employees.fields.travel_availability.help"
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextFormDivider
						label={translate("resources.employee-langs.name", 0)}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<ArrayInput
						source="employee_langs"
						label={false}
						className={classes.langsInput}
						{...props}
					>
						<SimpleFormIterator
							TransitionProps={{
								enter: false,
								exit: false,
							}}
							disableReordering={true}
							disableAdd={employeeLangs >= maxLangs}
							className={classes.langs}
						>
							<EmployeeLangsInput />
						</SimpleFormIterator>
					</ArrayInput>
				</Grid>
			</Grid>
			{form?.employee_type_id === 2 ? (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFormDivider label="resources.employees.fields.has_interview" />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12}>
							<BooleanInput
								{...props}
								source="has_interview"
								helperText="resources.employees.fields.has_interview.help"
							/>
							<br />
							{form?.has_interview === true && (
								<TextInput
									{...props}
									source="interview_description"
									multiline={true}
									rows={5}
									helperText="resources.employees.fields.interview_description.help"
								/>
							)}
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFormDivider label="resources.employees.fields.member" />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12}>
							<BooleanInput
								{...props}
								source="is_member"
								helperText="resources.employees.fields.is_member.help"
							/>
						</Grid>
						{form?.is_member === true && (
							<>
								<Grid item xs={12} sm={4}>
									<EuroInput
										{...props}
										source="subscribed_capital"
										validate={required()}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<EuroInput
										{...props}
										source="paid_capital"
										validate={required()}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<DateInput
										{...props}
										source="member_created"
										validate={required()}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default UserBasicInfoInput;
