import React, { useMemo } from "react";

import moment from "moment";
import { useTranslate } from "react-admin";

import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";

import { FormDivider } from "../form";

const useStyles = makeStyles((theme) => ({
	label: {
		fontWeight: "bold",
	},
	field: {
		justifyContent: "space-between",
		alignItems: "baseline",
		display: "flex",
	},
	value: {
		whiteSpace: "break-spaces",
		textAlign: "right",
		marginLeft: theme.spacing(1),
	},
}));

const Label = ({ source, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Typography variant="overline" className={classes.label}>
			{translate(`resources.employees.fields.${source}`)}:
		</Typography>
	);
};

const Value = ({ source, href, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const value = props.record?.[source] || "-";
	const text = useMemo(() => {
		if (typeof value === "boolean") {
			return value ? translate("ra.action.yes") : translate("ra.action.no");
		}
		return value;
	}, [value, translate]);

	return (
		<Typography variant="body2" component="span" className={classes.value}>
			{href && value ? <a href={href}>{text}</a> : text}
		</Typography>
	);
};

const Field = ({ source, size = 6, record, href, ...props }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={size}>
				<div className={classes.field}>
					<Label source={source} />
					<Value source={source} record={record} href={href} />
				</div>
			</Grid>
		</Grid>
	);
};

const EmployeesShow = (props) => {
	const { record } = props;
	const size = 12;

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
					{record?.fullname}
				</Typography>
				<Divider />
			</Grid>
			<Grid item xs={12} sm={5}>
				<Field
					source="phone"
					record={record}
					size={size}
					href={`phone:${record?.phone}`}
				/>
				<Field
					source="email"
					record={record}
					size={size}
					href={`mailto:${record?.email}`}
				/>
				<Field
					source="email_secondary"
					record={record}
					size={size}
					href={`mailto:${record?.email_secondary}`}
				/>
				<Field
					source="pec"
					record={record}
					size={size}
					href={`mailto:${record?.pec}`}
				/>
				<FormDivider />
				<Field source="tax_id_code" record={record} size={size} />
				<Field
					source="birth_city_id"
					label="resources.employees.fields.birth_city_id"
					record={{
						...record,
						birth_city_id: record?.birth_city?.fullname,
					}}
					size={size}
				/>
				{record?.birthdate && <Field
					source="birthdate"
					label="resources.employees.fields.birthdate"
					record={{
						...record,
						birthdate: moment(record?.birthdate).format("DD/MM/YYYY"),
					}}
					size={size}
				/>}
				<FormDivider />
				<Field
					source="city_id"
					label="resources.employees.fields.city_id"
					record={{
						...record,
						city_id: record?.city?.fullname,
					}}
					size={size}
				/>
				<Field source="address" record={record} size={size} />
				{record?.home_city_id && (
					<Field
						source="home_city_id"
						label="resources.employees.fields.home_city_id"
						record={{
							...record,
							home_city_id: record?.home_city?.fullname,
						}}
						size={size}
					/>
				)}
				{record?.home_address && (
					<Field source="home_address" record={record} size={size} />
				)}
			</Grid>
			<Grid item xs={12} sm={7}>
				<Field
					source="driving_license_id"
					record={{
						...record,
						driving_license_id: record?.driving_license?.name,
					}}
					size={size}
				/>
				<Field source="has_car" record={record} size={size} />
				<Field source="travel_availability" record={record} size={size} />
				<Field source="it_knowledge" record={record} size={size} />
				<FormDivider />
				<Field
					source="employee_langs"
					record={{
						...record,
						employee_langs: (record?.employee_langs || [])
							.reduce((acc, curr) => `${acc}${curr?.fullname}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<Field
					source="qualifications"
					record={{
						...record,
						qualifications: (record?._qualifications || [])
							.reduce((acc, curr) => `${acc}${curr?.name}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<Field
					source="work_experiences"
					record={{
						...record,
						work_experiences: (record?._work_experiences || [])
							.reduce((acc, curr) => `${acc}${curr?.name}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<FormDivider />
				{record?.employee_type_id === 2 && (
					<>
						<Field source="has_interview" record={record} size={size} />
						<Field source="interview_description" record={record} size={size} />
						<FormDivider />
					</>
				)}
				{record?.employee_type_id === 1 && (
					<>
						<Field source="is_member" record={record} size={size} />
						{record?.is_member && (
							<>
								<Field
									source="member_description"
									record={record}
									size={size}
								/>
							</>
						)}
						<FormDivider />
					</>
				)}
				<Field
					source="contracts"
					record={{
						...record,
						contracts: (record?.employee_contracts || [])
							.reduce((acc, curr) => `${acc}${curr?.fullname}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<Field
					source="jobs"
					record={{
						...record,
						jobs: (record?.employee_jobs || [])
							.reduce((acc, curr) => `${acc}${curr?.fullname}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<Field
					source="certificates"
					record={{
						...record,
						certificates: (record?.employee_certificates || [])
							.reduce((acc, curr) => `${acc}${curr?.fullname}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
				<FormDivider />
				<Field
					source="work_tools"
					record={{
						...record,
						work_tools: (record?._work_tools || [])
							.reduce((acc, curr) => `${acc}${curr?.name}, `, "")
							.slice(0, -2),
					}}
					size={size}
				/>
			</Grid>
		</Grid>
	);
};

export default EmployeesShow;
